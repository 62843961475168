import * as React from 'react';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import Container from '@lce/intl-ui/src/Layout/Container';
import Menu from '@lce/intl-ui/src/PageComponents/Menu';
import Helmet from 'react-helmet';

import Layout from '../ui/ConnectedLayout';
import useMenu from '../api/MenuPage/useMenu';

const MenuPage: React.FC<IDefaultPageProps> = props => {
  const { locale } = props.pathContext;
  const menuData = useMenu(locale);

  return (
    <>
      <Helmet>
        <meta content='noindex' name='robots' />
      </Helmet>
      <Layout {...props} >
        <Container sx={{ px: '16px' }}>
          <Menu {...menuData} />
        </Container>
      </Layout>
    </>
  );
};

export default MenuPage;
