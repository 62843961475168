import { graphql, useStaticQuery } from 'gatsby';
import { map as _map, isEmpty as _isEmpty, sortBy as _sortBy } from 'lodash';
import localeFilter, { localeFilterList } from '@lce/intl-util/src/localeFilter';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import removeLine from '@lce/intl-util/src/removeLine';
import { IMenuProps } from '@lce/intl-ui/src/PageComponents/Menu';
import { Direction } from '@lce/intl-types/src/IDirection';

import { ISiteConfigProps } from '../Config/useSiteConfig';

interface IDatoUseMenu extends ISiteConfigProps {
  menu: IDatoMenuNodes;
  activeMenuItems: IDatoActiveMenuItemNodes;
}

interface IDatoMenuNodes {
  nodes: IDatoMenu[];
}

interface IDatoActiveMenuItemNodes {
  nodes: IDatoActiveMenuItem[];
}

interface IDatoMenu {
  locale: string;
  textBar: string;
  bannerScript: string;
  bannerTitle: ILineObject[];
  bannerImage: IDatoImage;
  legalText: string;
}

interface IPrice {
  priceText: string;
  price: string;
  subscript: string;
}

interface IDatoActiveMenuItem {
  id: string;
  locale: string;
  nonDisplayTitle: string;
  isRight: boolean;
  position: number;
  customMenuItemComponent?: string;
  menuItem?: IMenuItem;
}

interface IMenuItem {
  locale: string;
  preHeader: ILineObject[];
  name: ILineObject[];
  image: {
    alt?: string;
    url: string;
  };
  price: IPrice[];
  description: ILineObject[];
  icon: IDatoImage;
}

// eslint-disable-next-line max-lines-per-function
const useMenu = (locale: string) => {
  const data: IDatoUseMenu = useStaticQuery(graphql`
    query UseMenuQuery {
      menu: allDatoCmsMenu {
        nodes {
          locale
          textBar
          bannerScript
          bannerTitle {
            line
          }
          bannerImage {
            url
          }
          legalText
        }
      }
      activeMenuItems: allDatoCmsActiveMenuItem {
        nodes {
          locale
          id
          position
          isRight
          customMenuItemComponent
          menuItem {
            locale
            title
            preHeader {
              line
            }
            name {
              line
            }
            image {
              url
            }
            price {
              priceText
              price
              subscript
            }
            description {
              line
            }
            icon {
              url
            }
          }
        }
      }
      siteConfig: allDatoCmsSiteConfig {
        nodes {
          isRtl
          locale
        }
      }
    }
  `);
  const filteredMenu = localeFilter<IDatoMenu>(data.menu.nodes, locale);
  const filteredDirection = localeFilter(data?.siteConfig?.nodes, locale);

  const direction: Direction = filteredDirection && filteredDirection.isRtl ? 'rtl' : 'ltr';

  const filteredActiveMenuItems = localeFilterList<IDatoActiveMenuItem>(data.activeMenuItems.nodes, locale);
  const cleanedActiveMenuItems = _map(filteredActiveMenuItems, activeMenuItem => {
    const menuItem = activeMenuItem.menuItem &&
      !_isEmpty(activeMenuItem.menuItem) ? {
        ...activeMenuItem.menuItem,
        description: removeLine(activeMenuItem.menuItem.description),
        icon: activeMenuItem.menuItem.icon && activeMenuItem.menuItem.icon.url,
        image: activeMenuItem.menuItem.image.url,
        itemName: removeLine(activeMenuItem.menuItem.name),
        preHeader: removeLine(activeMenuItem.menuItem.preHeader),
      } : undefined;
    return {
      ...activeMenuItem,
      menuItem,
    };
  });

  const simpleBanner = {
    script: filteredMenu.bannerScript,
    title: removeLine(filteredMenu.bannerTitle),
    image: filteredMenu.bannerImage && filteredMenu.bannerImage.url,
  };
  delete filteredMenu.bannerImage;
  delete filteredMenu.bannerTitle;
  delete filteredMenu.bannerScript;

  const sortedActiveMenuItems = _sortBy(cleanedActiveMenuItems, (activeMenuItems) => activeMenuItems.position);

  const menuPage: IMenuProps = {
    menu: {
      ...filteredMenu,
      simpleBanner: simpleBanner.title && simpleBanner.image ? { ...simpleBanner, direction } : undefined,
    },
    activeMenuItems: {
      ...sortedActiveMenuItems,
    },
  };

  return menuPage;

};

export default useMenu;
